.App {
  text-align: center;
  display: flex;
  align-items: center;
}
.description{
  font-size: 3vh;
  margin-bottom: 5vh;
}

.background{
  background-color: white;
  padding: 4vh;
  border-radius: 2vh;
  width: 100%;
}

.skills{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100% ;
}

.skill{
  margin-bottom: 2em;
  font-weight: bold;
}

.skills_tagline{
  text-align: left;
  margin-bottom: 5em;
}

.skill_illustration{
  max-width: 50px;
  max-height: 50px;
  margin: 0 3em 0 3em;
}

.reach_out{
  margin: 2em 0 2em 0;
}