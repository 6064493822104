.contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    align-items: center;
}

.logo{
    margin: 0vh 1vh;
}

.in_project{
    width: 22px;
}

.text{
    text-align: justify;
    font-size: 2.5vh;
}

h2{
    text-align: left;
}
.project{
    display: flex;
    flex-direction: column;
}

.project_header{
    text-align: left    ;
}

.project_body{
    display: flex;
    text-align: left;
    margin-bottom: 7vh;
}

.project_card{ 
    display: flex;
    flex-direction: column;
    margin-left: 2vh;
    justify-content: flex-start;
}

.project_image{
    border: 2px solid #333;
}

a{
    text-decoration: none;
    color: #333;
}

.project_links{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.artist{
    margin-bottom: 2em;
  }
  
  .link{
    margin-right: 20px;
  }
  
  
@media screen and (max-width: 992px) {
    .project_header{
        text-align: center;
    }

    .project_links{
        justify-content: center;
        align-items: center;
    }

    .project_description{
        text-align: center;
    }

    .project_body{
      flex-direction: column;
      align-items: center;
    }

    .project_image{
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
    }

    .artist{
        text-align: center;
    }

  } 