html{
  background-color: #C6E9F6;
}

body {
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 4vh;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1400px) {
  body{
    margin-left: 21%;
    margin-right: 21%;
  }
}

@media screen and (max-width: 1200px) {
  body{
    margin-left: 18%;
    margin-right: 18%;
  }
}

@media screen and (max-width: 992px) {
  body{
    margin-left: 14%;
    margin-right: 14%;
  }
}

@media screen and (max-width: 768px) {
  body{
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media screen and (max-width: 576px) {
  body{
    margin-left: 0%;
    margin-right: 0%;
  }
}
